$text: rgb(240, 234, 229);
$cover: rgb(240, 234, 229);
$textGlowing: 0 0 5px #fff;
$background: rgb(3, 2, 2);
$primary: rgb(113, 86, 65);
$secondary: rgb(23, 17, 13);
$accent: rgb(152, 116, 87);

/* Remove circular import */
/* @import "./index.scss"; */

body {
  margin: 0;
  padding: 0;
  background: linear-gradient(135deg, #0f172a 0%, #1e293b 100%);
  color: #f8fafc;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #1e293b;
}

::-webkit-scrollbar-thumb {
  background: #3b82f6;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #60a5fa;
}

::selection {
  background: rgba(59, 130, 246, 0.7);
  color: white;
}

/* Add smooth scroll behavior */
html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px; /* Adjust for fixed navbar */
}

/* Animation keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Animation utilities */
.animate-fadeIn {
  animation: fadeIn 0.6s ease-in-out forwards;
}

.animate-slideUp {
  animation: slideUp 0.5s ease-out forwards;
}

/* Delay utilities */
.delay-1 {
  animation-delay: 0.1s;
}

.delay-2 {
  animation-delay: 0.2s;
}

.delay-3 {
  animation-delay: 0.3s;
}