@import "../../GlobalStyles.scss";

.wrapper{
    position: relative;
    overflow: hidden;
}

.cover{
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 0;
    right: 0;
    background-color: $cover;
    z-index: 20;
}