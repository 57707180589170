@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./GlobalStyles.scss";

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth
}

body{
    color: $text;
    background-color: $background;
    font-family: "Nunito";
    ::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: rgb(0, 0, 0);
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #88888863;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #88888878;
        }

}
